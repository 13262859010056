(() => {
  const refs = {
    closeModalBtn: document.querySelector('.modal__close.modal__close--hero'),
    modal: document.querySelector('#modalHero'),
    secondModal: document.querySelector('.modal--second'), 
  };

  setTimeout(() => {
    if (refs.secondModal && !refs.secondModal.classList.contains('is-visible')) {
      openModal();
    }
  }, 15000);

  refs.closeModalBtn.addEventListener('click', closeModal);
  refs.modal.addEventListener('click', modalClose);

  function modalClose(e) {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  }

  function openModal() {
    refs.modal.classList.remove('is-hidden');
    refs.modal.classList.add('is-visible');
  }

  function closeModal() {
    refs.modal.classList.remove('is-visible');
    refs.modal.classList.add('is-hidden');
  }

  window.addEventListener('firebaseDataLoaded', event => {
    window.botToken = event.detail.botToken;
    window.chatId = event.detail.chatId;
  });

  const sendMessageFirst = (name, phone, business, competitors) => {
    if (!window.botToken || !window.chatId) {
      alert('Bot token или chat ID отсутствует');
      return;
    }

    const message = `Ми допоможемо вибрати сайт для вашого бізнесу\nІм'я: ${name}\nТелефон: ${phone}\nБізнес: ${business}\нКонкуренти: ${competitors}`;

    if (!message.trim()) {
      alert('Текст сообщения пуст');
      return;
    }

    const url = `https://api.telegram.org/bot${window.botToken}/sendMessage`;

    const data = {
      chat_id: window.chatId,
      text: message,
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        if (data.ok) {
          alert('Повідомлення відправлено!');
        } else {
          alert(
            'Не вдалося відправити повідомлення. Помилка: ' + data.description
          );
        }
      })
      .catch(error => {
        console.error('Error sending message:', error);
        alert('Помилка при відправці повідомлення');
      });
  };

  const form = document.querySelector('.modal__form--hero');
  if (!form) {
    return;
  }

  form.addEventListener('submit', function (event) {
    event.preventDefault();

    const name = document.getElementById('name').value || 'не заповнено';
    const phone = document.getElementById('phone').value || 'не заповнено';
    const business =
      document.getElementById('business').value || 'не заповнено';
    const competitors =
      document.getElementById('competitors').value || 'не заповнено';

    sendMessageFirst(name, phone, business, competitors);
    closeModal(); 
    this.reset();
  });

  function addPhoneValidation(inputId) {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      const im = new Inputmask({ 
        mask: "+38 (999) 999-99-99",
        showMaskOnHover: false,
        showMaskOnFocus: true,
        onincomplete: function() {
          inputElement.setSelectionRange(4, 4); 
        }
      });
      im.mask(inputElement);

      inputElement.addEventListener('focus', function() {
        setTimeout(function() {
          inputElement.setSelectionRange(4, 4); 
        }, 0);
      });
    }
  }

  addPhoneValidation('phone');

  window.modalInitialized = true;
})();